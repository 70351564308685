import React, { useRef, useCallback, useEffect } from 'react';
import { GOOGLE_API_KEY } from '@config/environment';
import { geoFence, outsidePaintZone } from '@utils/validators/GeoFence';
import { MapContainer } from './styles/MapGeoFence.styles';

declare global {
  interface Window {
    isGeoFence: boolean;
  }
}

interface MapGeoFenceProps {
  zoom?: number;
}
const CENTER_COORDS = {
  lat: -33.44,
  lng: -70.623911,
};
const MEKI_COORDS = {
  lat: -33.3925354,
  lng: -70.623911,
};
function loadScript(src: string, position: HTMLElement | null, id: string) {
  if (!position) {
    return;
  }
  const script = document.createElement('script');
  script.async = true;
  script.defer = true;
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

export function MapGeoFence(props: MapGeoFenceProps) {
  const { zoom = 10 } = props;
  const loaded = useRef(false);
  const mapId = useRef(Math.random());
  const defaultCenter = CENTER_COORDS;
  const mapRef = useRef<google.maps.Map>(null);
  const initMap = useCallback(
    function initMapFunc(): void {
      const element = document.getElementById(mapId.current.toString());
      if (!element) {
        setTimeout(() => {
          initMapFunc();
        }, 500);
        return;
      }
      const allowedBounds = new google.maps.LatLngBounds(
        new google.maps.LatLng(
          outsidePaintZone[0].lat,
          outsidePaintZone[0].lng,
        ),
        new google.maps.LatLng(
          outsidePaintZone[2].lat,
          outsidePaintZone[2].lng,
        ),
      );
      mapRef.current = new google.maps.Map(element, {
        center: new google.maps.LatLng(defaultCenter.lat, defaultCenter.lng),
        zoom,
        minZoom: 10,
        fullscreenControl: false,
        streetViewControl: false,
        mapTypeControl: false,
        restriction: {
          latLngBounds: allowedBounds,
          strictBounds: true,
        },
      });
      new google.maps.Marker({
        map: mapRef.current,
        position: MEKI_COORDS,
        icon: {
          url: '/assets/images/pinfarmacia.png',
          scaledSize: new google.maps.Size(75.4 / 2, 98.4 / 2),
        },
      });
      const combinedPaths = [outsidePaintZone, geoFence];
      new google.maps.Polygon({
        paths: combinedPaths,
        fillColor: '#cc0000',
        fillOpacity: 0.1,
        map: mapRef.current,
        strokeColor: '#cc0000',
        strokeWeight: 1,
      });
    },
    [defaultCenter, zoom],
  );

  useEffect(() => {
    if (typeof window !== 'undefined' && !loaded.current) {
      if (!document.querySelector('#google-maps-script')) {
        window.initMap = function localInitMap() {
          initMap();
          window.initMap = undefined;
        };
        loadScript(
          `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&libraries=places&callback=initMap&language=es-419&region=cl`,

          document.querySelector('head'),
          'google-maps-script',
        );
      } else {
        if (!window.initMap) {
          initMap();
        }
      }
      loaded.current = true;
    }
  }, [initMap]);

  return (
    <MapContainer id={mapId.current.toString()}>
      <></>
    </MapContainer>
  );
}
