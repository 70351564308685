export const outsidePaintZone = [
  { lat: -35.593687, lng: -77.158622 },
  { lat: -35.593687, lng: -66.457938 },
  { lat: -31.164653, lng: -66.457938 },
  { lat: -31.164653, lng: -77.158622 },
];
export const geoFence = [
  { lng: -70.5340809698431, lat: -33.31755335108771 },
  { lng: -70.49388069182629, lat: -33.324885575754486 },
  { lng: -70.48191978314313, lat: -33.34530658895768 },
  { lng: -70.47352166738044, lat: -33.35444778970749 },
  { lng: -70.47617639104433, lat: -33.36448256792787 },
  { lng: -70.49202716926878, lat: -33.36919066154004 },
  { lng: -70.49000547517346, lat: -33.38950561793633 },
  { lng: -70.50502305468201, lat: -33.418504610278966 },
  { lng: -70.51589245866941, lat: -33.425216616396575 },
  { lng: -70.513052628196, lat: -33.46851239892246 },
  { lng: -70.50944889373103, lat: -33.475848251181084 },
  { lng: -70.51192414205464, lat: -33.47939652266697 },
  { lng: -70.51033455774966, lat: -33.49123869197398 },
  { lng: -70.51352559785931, lat: -33.49714236379804 },
  { lng: -70.51396214993906, lat: -33.503529946025886 },
  { lng: -70.52418053074624, lat: -33.52302751889035 },
  { lng: -70.52934274634562, lat: -33.53136082066527 },
  { lng: -70.53937325465306, lat: -33.537919640599796 },
  { lng: -70.54438073229515, lat: -33.54677554004583 },
  { lng: -70.53095397257368, lat: -33.552408881207846 },
  { lng: -70.52953116213591, lat: -33.56154412058259 },
  { lng: -70.53059249376084, lat: -33.580344816724576 },
  { lng: -70.52857905744159, lat: -33.58500698946592 },
  { lng: -70.5309190881835, lat: -33.59450010023223 },
  { lng: -70.53021301407644, lat: -33.59698407977191 },
  { lng: -70.53255554517756, lat: -33.601890454387494 },
  { lng: -70.53282353278706, lat: -33.6036333778377 },
  { lng: -70.53073287710008, lat: -33.60724856911912 },
  { lng: -70.53090781912333, lat: -33.61417308975203 },
  { lng: -70.53141646640618, lat: -33.61424340369044 },
  { lng: -70.53643151303022, lat: -33.617073671773724 },
  { lng: -70.54008242105692, lat: -33.615612285196526 },
  { lng: -70.5435415843577, lat: -33.61556527494975 },
  { lng: -70.54545900282191, lat: -33.615017657463326 },
  { lng: -70.54712801734355, lat: -33.61476048402403 },
  { lng: -70.56734538916263, lat: -33.620747851133295 },
  { lng: -70.57353676525264, lat: -33.623317543432535 },
  { lng: -70.5862969546623, lat: -33.63027424925055 },
  { lng: -70.58951176784433, lat: -33.63191984651928 },
  { lng: -70.59277149304503, lat: -33.63158353862474 },
  { lng: -70.59290533536887, lat: -33.63031949503178 },
  { lng: -70.59420403918726, lat: -33.62409564044748 },
  { lng: -70.59598384609784, lat: -33.61488478790813 },
  { lng: -70.59975892665543, lat: -33.61521627734471 },
  { lng: -70.60261472496292, lat: -33.60957183627135 },
  { lng: -70.60279605498778, lat: -33.60656026153878 },
  { lng: -70.605886859486, lat: -33.59788338499931 },
  { lng: -70.62689756323437, lat: -33.60034831552053 },
  { lng: -70.62919492315346, lat: -33.57703785612881 },
  { lng: -70.64481707400684, lat: -33.575705973893996 },
  { lng: -70.66023049742024, lat: -33.57375300132067 },
  { lng: -70.66196783173622, lat: -33.58040314832585 },
  { lng: -70.66344808691017, lat: -33.585359621355096 },
  { lng: -70.67435086300132, lat: -33.619687863341156 },
  { lng: -70.67634785285145, lat: -33.62069363784915 },
  { lng: -70.67968851107291, lat: -33.61982235347107 },
  { lng: -70.68071312288897, lat: -33.61933406912124 },
  { lng: -70.6818461068388, lat: -33.619116520172895 },
  { lng: -70.68239618262277, lat: -33.61663582514881 },
  { lng: -70.69656434935929, lat: -33.61129327142051 },
  { lng: -70.69695150978644, lat: -33.61532950512341 },
  { lng: -70.6985053035217, lat: -33.61898809489572 },
  { lng: -70.69921482679526, lat: -33.618662651919486 },
  { lng: -70.7070824321706, lat: -33.615651048548024 },
  { lng: -70.71158211568111, lat: -33.61400052018032 },
  { lng: -70.71576979209021, lat: -33.6135359581455 },
  { lng: -70.71597371443099, lat: -33.60433712455902 },
  { lng: -70.71381021110984, lat: -33.587239545385366 },
  { lng: -70.71342560264189, lat: -33.5706171856882 },
  { lng: -70.74278873069929, lat: -33.5467445997527 },
  { lng: -70.75972713124708, lat: -33.56264085261284 },
  { lng: -70.76802120784023, lat: -33.57170040809892 },
  { lng: -70.78089010300343, lat: -33.568894696502284 },
  { lng: -70.79797380758923, lat: -33.57717737082461 },
  { lng: -70.81094473246992, lat: -33.56612607122262 },
  { lng: -70.79850972799586, lat: -33.55580888328373 },
  { lng: -70.80445222339891, lat: -33.551777249470156 },
  { lng: -70.80961103754741, lat: -33.547312461487785 },
  { lng: -70.80621183858392, lat: -33.54367648895268 },
  { lng: -70.80401986373076, lat: -33.53802814765642 },
  { lng: -70.79996806674941, lat: -33.52840121051706 },
  { lng: -70.79962789899109, lat: -33.52286191175647 },
  { lng: -70.79957478539444, lat: -33.51918388332969 },
  { lng: -70.79948631846187, lat: -33.518926497322575 },
  { lng: -70.79935830867821, lat: -33.518661999140804 },
  { lng: -70.79951661024046, lat: -33.51841185880507 },
  { lng: -70.79949597980256, lat: -33.518038964021294 },
  { lng: -70.79961120709798, lat: -33.51688192049263 },
  { lng: -70.79964841372023, lat: -33.51567348101139 },
  { lng: -70.79960077884839, lat: -33.51430415606793 },
  { lng: -70.79960549801461, lat: -33.5127966596908 },
  { lng: -70.79957531448794, lat: -33.51101269087485 },
  { lng: -70.79953239878228, lat: -33.509591862441994 },
  { lng: -70.80023989097019, lat: -33.50914593023975 },
  { lng: -70.80160898076277, lat: -33.507682517351384 },
  { lng: -70.79723067561878, lat: -33.49918554348022 },
  { lng: -70.78554330625093, lat: -33.496188907552984 },
  { lng: -70.78686848233045, lat: -33.49098512330935 },
  { lng: -70.76520496187196, lat: -33.47982339969227 },
  { lng: -70.78362706064843, lat: -33.46303644441759 },
  { lng: -70.78774742381043, lat: -33.452549001317365 },
  { lng: -70.78600908819108, lat: -33.44646842206619 },
  { lng: -70.78538447062456, lat: -33.44358962050774 },
  { lng: -70.78587537964385, lat: -33.441599448194864 },
  { lng: -70.78608342269354, lat: -33.43890109166597 },
  { lng: -70.78644482144385, lat: -33.43324130362234 },
  { lng: -70.7855333233296, lat: -33.426636949125204 },
  { lng: -70.78371032710103, lat: -33.41909364833023 },
  { lng: -70.78459019016188, lat: -33.40684002820463 },
  { lng: -70.74562925521742, lat: -33.37169713613439 },
  { lng: -70.76350439982318, lat: -33.368100113750344 },
  { lng: -70.76654637515445, lat: -33.36397764739507 },
  { lng: -70.76927131221147, lat: -33.355958909901204 },
  { lng: -70.77844491193949, lat: -33.3387421115004 },
  { lng: -70.78168858000548, lat: -33.3248265537677 },
  { lng: -70.77391114699658, lat: -33.320266734081 },
  { lng: -70.76715939662193, lat: -33.31598952943832 },
  { lng: -70.76818249051941, lat: -33.313029232641995 },
  { lng: -70.75289022514977, lat: -33.30667900963587 },
  { lng: -70.74540980049787, lat: -33.29982240992626 },
  { lng: -70.733386823332, lat: -33.294615044609756 },
  { lng: -70.75068156616227, lat: -33.25742653887547 },
  { lng: -70.72681835360068, lat: -33.24946731746886 },
  { lng: -70.72192895957147, lat: -33.24886035901123 },
  { lng: -70.71468698075842, lat: -33.243924901759264 },
  { lng: -70.71119372248128, lat: -33.24514988886815 },
  { lng: -70.70998338232627, lat: -33.24624854456293 },
  { lng: -70.70747698130964, lat: -33.244670850919235 },
  { lng: -70.70449238223898, lat: -33.24296691239341 },
  { lng: -70.70289448301854, lat: -33.24215044858357 },
  { lng: -70.70173363690589, lat: -33.241892590761516 },
  { lng: -70.69890189317545, lat: -33.241803463350585 },
  { lng: -70.69821990874058, lat: -33.24178873249102 },
  { lng: -70.69539491768005, lat: -33.23676948044425 },
  { lng: -70.69371927530972, lat: -33.23694038090557 },
  { lng: -70.68623857200562, lat: -33.23691770445718 },
  { lng: -70.67901387460819, lat: -33.23625264878832 },
  { lng: -70.67192931842294, lat: -33.22680012799294 },
  { lng: -70.66357180959302, lat: -33.2226717050581 },
  { lng: -70.65622204312022, lat: -33.219547333139644 },
  { lng: -70.64048812062836, lat: -33.22164441472576 },
  { lng: -70.63415809575838, lat: -33.230703707897995 },
  { lng: -70.65301032984607, lat: -33.238026040471176 },
  { lng: -70.66223180052725, lat: -33.24312503731243 },
  { lng: -70.66291089128127, lat: -33.24508151123281 },
  { lng: -70.66461913440148, lat: -33.246053859070045 },
  { lng: -70.66462379354883, lat: -33.24681607703156 },
  { lng: -70.66577100901034, lat: -33.247053590823754 },
  { lng: -70.66561804004093, lat: -33.24760707707848 },
  { lng: -70.66538189638177, lat: -33.24791692087468 },
  { lng: -70.65589888948041, lat: -33.26119185687075 },
  { lng: -70.62029492655634, lat: -33.24225939394838 },
  { lng: -70.53404829900131, lat: -33.31752702152211 },
];

interface IPoint {
  lat: number;
  lng: number;
}

function toRadians(degrees: number): number {
  return degrees * (Math.PI / 180);
}

function calculateDistance(
  point: IPoint,
  lineStart: IPoint,
  lineEnd: IPoint,
): number {
  const A = point.lat - lineStart.lat;
  const B = point.lng - lineStart.lng;
  const C = lineEnd.lat - lineStart.lat;
  const D = lineEnd.lng - lineStart.lng;

  const dot = A * C + B * D;
  const len_sq = C * C + D * D;
  let param = -1;
  if (len_sq != 0)
    //in case of 0 length line
    param = dot / len_sq;

  let xx, yy;

  if (param < 0) {
    xx = lineStart.lat;
    yy = lineStart.lng;
  } else if (param > 1) {
    xx = lineEnd.lat;
    yy = lineEnd.lng;
  } else {
    xx = lineStart.lat + param * C;
    yy = lineStart.lng + param * D;
  }

  const dx = point.lat - xx;
  const dy = point.lng - yy;
  return Math.sqrt(dx * dx + dy * dy);
}

export function isPointInsideConcaveGeoFence(
  point: IPoint,
  accuracy = 0.00000000000001,
): boolean {
  const lat = toRadians(point.lat);
  const lng = toRadians(point.lng);
  let inside = false;

  for (let i = 0, j = geoFence.length - 1; i < geoFence.length; j = i++) {
    const xi = toRadians(geoFence[i].lat);
    const yi = toRadians(geoFence[i].lng);
    const xj = toRadians(geoFence[j].lat);
    const yj = toRadians(geoFence[j].lng);

    const intersect =
      yi > lng != yj > lng && lat < ((xj - xi) * (lng - yi)) / (yj - yi) + xi;

    if (
      intersect ||
      calculateDistance(point, { lat: xi, lng: yi }, { lat: xj, lng: yj }) <=
        accuracy
    )
      inside = !inside;
  }

  return inside;
}
