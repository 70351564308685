import { styled } from '@mui/system';
import { Container } from '@mui/material';

export const MapContainer = styled(Container)({
  margin: '0',
  padding: 0,
  maxHeight: 600,
  height: 'calc(100vh)',
  zIndex: 99,
});
