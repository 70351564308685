import { styled } from '@mui/system';
import { IconButton } from '@mui/material';

const ModalHeader = styled('div')(({ theme }) => ({
  fontSize: '24px',
  fontWeight: 600,
  lineHeight: '36px',
  fontFamily: 'Poppins',
  textAlign: 'center',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  padding: '8px 0px',
  [theme.breakpoints.down('md')]: {
    fontSize: '16px',
    lineHeight: '24px',
  },
}));

const BackButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  left: '10px',
  fontSize: '16px',
  fontWeight: 600,
  padding: 0,
  [theme.breakpoints.down('md')]: {
    fontSize: '12px',
    lineHeight: '18px',
  },
}));

const MapContainer = styled('div')(() => ({
  flexGrow: 1,
  width: '100%',
  overflow: 'hidden',
}));

const ModalContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  backgroundColor: 'white',
  width: '60%',
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: '32px',
  overflow: 'hidden',
  paddingTop: '8px',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    height: '100dvh',
    borderRadius: '0px',
  },
}));

export { ModalHeader, BackButton, MapContainer, ModalContainer };
