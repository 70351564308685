import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import { MapGeoFence } from '@components/MapGeoFence/MapGeoFence';
import { Modal } from '@mui/material';
import {
  BackButton,
  MapContainer,
  ModalContainer,
  ModalHeader,
} from './modalDispatchZones.styles';

interface ModalDispatchZonesProps {
  isModalOpen: boolean;
  handleCloseModal: () => void;
}

export const ModalDispatchZones = ({
  isModalOpen,
  handleCloseModal,
}: ModalDispatchZonesProps) => {
  return (
    <Modal open={isModalOpen} onClose={handleCloseModal}>
      <ModalContainer>
        <ModalHeader>
          <BackButton
            onClick={handleCloseModal}
            color="inherit"
            aria-label="back"
            size="large"
          >
            <ArrowBackIosRoundedIcon />
          </BackButton>
          Zonas de despacho
        </ModalHeader>
        <MapContainer>
          <MapGeoFence zoom={10} />
        </MapContainer>
      </ModalContainer>
    </Modal>
  );
};
